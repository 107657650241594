import { Directive, EventEmitter, HostListener, Input, Optional, Output, Self } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[KeyPressValidator]',
  providers: [NgModel]
})
export class KeyPressValidatorDirective {
  @Input('KeyPressValidator') regexPattern!: RegExp;
  @Input('KeyPressType') type: 'Allow' | 'Restrict' = 'Allow';
  @Input() removePattern: string = '';
  @Input() clearZero?: boolean;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  constructor(@Optional() private model?: NgModel, @Optional() @Self() private ngControl?: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    let newValue = '';
    if (this.type === 'Restrict') {
      newValue = value.replace(new RegExp(this.regexPattern, 'g'), '');
    } else {
      if (this.regexPattern) {
        newValue = value.replace(new RegExp(this.removePattern, 'g'), '');
      } else {
        newValue = value.replace(new RegExp(this.regexPattern, 'g'), '');
      }
    }
    this.model?.valueAccessor!.writeValue(newValue);
    this.ngModelChange.emit(newValue);
    this.ngControl?.control!.setValue(newValue);
  }
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (event.key === '.' && (event.target! as any).value!.includes(event.key)) {
      return false;
    }
    return this.type === 'Allow'
      ? new RegExp(this.regexPattern, 'g').test(event.key)
      : !new RegExp(this.regexPattern, 'g').test(event.key);
  }
  @HostListener('focusout', ['$event.target.value'])
  onFocusOut(value: string) {
    if (this.clearZero && value?.toString()?.match(/[0]/g)?.length === value?.length) {
      this.model?.valueAccessor!.writeValue('');
      this.ngControl?.control!.setValue('');
      this.ngModelChange.emit('');
    }
  }
}
