import { environment } from '../../../environments/environment';

// User Auth
export const LOGIN_USER = environment.baseURL + '/User/SignIn';
// Master Screen
export const GET_LEGAL_EMPLOYEE_LIST = environment.baseURL + '/hrmaster/GetHRLegalEmpList';
export const GET_REFERANCE_AREA_LIST = environment.baseURL + '/hrmaster/GetReferenceAreaList';
export const GET_FIELD_LIST = environment.baseURL + '/hrmaster/GetFieldsList';
export const GET_MASTER_TABLE_LIST = environment.baseURL + '/hrmaster/GetHRMasterList';
export const SAVE_MASTER_DATA = environment.baseURL + '/hrmaster/SaveMasterList';
export const EXCEL_EXPORT = environment.baseURL + '/hrmaster/ExportHRMasterExcel';
export const EXCEL_UPLOAD = environment.baseURL + '/hrmaster/ImportHRMasterExcel';
export const TABLE_LABELS = environment.baseURL + '/hrmaster/GetHRMasterDynmaicLabels';
// Employee Screen
// Personal
export const EMP_DROPDOWN = environment.baseURL + '/employee/GetEmpCombos';
export const EMP_FILE_UPLOAD = environment.baseURL + '/employee/AttachDocLatest';
export const EMP_FILE_DOWNLOAD = environment.baseURL + '/employee/DownloadFile';
export const SAVE_EMP_PERSONAL = environment.baseURL + '/employee/SaveEmpPersonalDetails';
export const GET_EMP_PERSONAL = environment.baseURL + '/employee/EmpPersonalRefresh';
export const DELETE_EMP_PERSONAL = environment.baseURL + '/employee/DeleteEmpPersonal';
export const DELETE_REFRESH_EMP_PERSONAL = environment.baseURL + '/employee/DeleteEmpPersonalRefresh';
export const EMPLOYEE_SEARCH = environment.baseURL + '/employee/EmpCodeRefresh';
export const EMPLOYEE_HISTORY_DROPDOWN = environment.baseURL + '/employee/GetEmpHistoryCombo';
export const EMPLOYEE_HISTORY_ONSELECT = environment.baseURL + '/employee/EmpPersonalHistoryOnSelect';
export const EMPLOYEE_CODE_SEARCH = environment.baseURL + '/employee/GetEmpCodeHelp';
export const EMPLOYEE_DYNAMIC_LABELS = environment.baseURL + '/employee/GetPersonalAdditionalDynamicLabels';

// Assignment
export const EMP_ADDITIONAL_DROPDOWN = environment.baseURL + '/employee/GetEmpAdditionalAssgnCombos';
export const SAVE_EMP_ASSIGNMENT = environment.baseURL + '/employee/SaveEmpAssgnDetails';
export const SAVE_EMP_ASSIGNMENT_REFRESH = environment.baseURL + '/employee/SaveEmpAssgnDetailsRefresh';
export const EMP_ASSIGNMENT_HISTORY_DROPDOWN = environment.baseURL + '/employee/GetEmpAssignHistoryCombo';
export const DELETE_EMP_ASS_PERSONAL = environment.baseURL + '/employee/DeleteEmpAssgnDetails';
export const DELETE_EMP_REFRESH_ASS_PERSONAL = environment.baseURL + '/employee/DeleteEmpAssgnDetailsRefresh';
export const EMP_ASSIGNMENT_SEARCH = environment.baseURL + '/employee/EmpCodeAssignRefresh';
export const EMP_ASSIGNMENT_HISTORY_ONSELECT = environment.baseURL + '/employee/EmpHistoryAssgnOnSelect';
export const EMP_ASSIGNMENT_DYNAMIC_LABELS = environment.baseURL + '/employee/GetAssgnAdditionalDynamicLabels';

// Salary
export const SAVE_EMP_SALARY_REFRESH = environment.baseURL + '/employee/SaveEmpSalaryDetailsRefresh';
export const SAVE_EMP_SALARY = environment.baseURL + '/employee/SaveEmpSalaryDetails';
export const EMP_SALARY_DROPDOWN = environment.baseURL + '/employee/GetCTCCombos';
export const EMP_SALARY_CODE_DROPDOWN = environment.baseURL + '/employee/GetCTCEmpHelp';
export const EMP_SALARY_CODE_SEARCH = environment.baseURL + '/employee/GetCTCEmpOnEnter';
export const EMP_SALARY_HISTORY_DROPDOWN = environment.baseURL + '/employee/GetCTCHistoryCombo';
export const EMP_SALARY_HISTORY_ONSELECT = environment.baseURL + '/employee/GetCTCHistoryComboOnSelect';
export const EMP_SALARY_PAY_ELEMENT = environment.baseURL + '/employee/GetCTCPayElement';

// Payroll
export const PAYROLL_DROPDOWN_ENTITY = environment.baseURL + '/PayRoll/GetLegalEntity';
export const PAYROLL_DROPDOWN_PAYROLLTYPE = environment.baseURL + '/PayRoll/GetPayRollType';
export const PAYROLL_DROPDOWN_PRPROCESS_PERIOD = environment.baseURL + '/PayRoll/GetPRProcessPeriod';
export const PAYROLL_DROPDOWN_PRPROCESS_TYPE = environment.baseURL + '/PayRoll/GetPRProcessType';
export const PAYROLL_DETAILS = environment.baseURL + '/PayRoll/GetPYGetDetails';
export const PAYROLL_REGISTER_DOWNLOAD = environment.baseURL + '/PayRoll/GetRegisterDownload';
export const PAYROLL_PAYSLIP = environment.baseURL + '/PayRoll/DownloadAllPYSlip';
export const JV_DOWNLOAD = environment.baseURL + '/PayRoll/GetJVDownload';
export const BANK_FILE_DOWNLOAD = environment.baseURL + '/PayRoll/DownloadBankFile';
export const PROCESS_PAYROLL = environment.baseURL + '/PayRoll/ProcessPayrollEmp';
export const FULL_PROCESS_PAYROLL = environment.baseURL + '/PayRoll/ProcessPayroll';
export const PAYROLL_EMPLOYEE_STATUS = environment.baseURL + '/PayRoll/GetEmpstatusForGrid';
export const PAYROLL_EXPORT_TABLE = environment.baseURL + '/PayRoll/ExportPYDetails';
export const PAYROLL_EMP_SEARCH = environment.baseURL + '/PayRoll/GetPyEmpHelp';
export const AUTHORIZE_ALL = environment.baseURL + '/PayRoll/AuthorizeAll';
export const UNAUTHORIZE_ALL = environment.baseURL + '/PayRoll/UnAuthorizeAll';
export const AUTHORIZE = environment.baseURL + '/PayRoll/AuthorizeEmp';
export const UNAUTHORIZE = environment.baseURL + '/PayRoll/UnAuthorizeEmp';
export const PAYROLL_EXCEL_IMPORT = environment.baseURL + '/PayRoll/PayRollImportEmp';
export const PAYROLL_QUEUE_STATUS = environment.baseURL + '/PayRoll/GetPayRollQueueStatus';

// Data Upload/Download
export const DU_INTERFACE_TYPE_LIST = environment.baseURL + '/DataUD/GetInterfaceTypeList';
export const DU_FILE_TYPE_LIST = environment.baseURL + '/DataUD/GetFileTypeList';
export const DU_MAP_LIST = environment.baseURL + '/DataUD/GetMapList';
export const DU_DATA_TYPE_LIST = environment.baseURL + '/DataUD/GetDataTypesList';
export const DU_LEGAL_EMPLOYER_LIST = environment.baseURL + '/DataUD/GetDULegalEmployerList';
export const DU_SEPERATOR_LIST = environment.baseURL + '/DataUD/GetSeperatorsList';
export const DU_INTERFACE_FETCH = environment.baseURL + '/DataUD/GetInterfaceFetch';
export const DU_GET_DETAILS = environment.baseURL + '/DataUD/GetLinkInfo';
export const DU_SUMMIT = environment.baseURL + '/DataUD/DataDUSubmit';
export const DU_DELETE = environment.baseURL + '/DataUD/DeleteInterface';
export const DU_GET_DATA = environment.baseURL + '/DataUD/GetDataDU';
export const DU_TABLE_NAMES = environment.baseURL + '/DataUD/GetTableName';
export const DU_PROCESS_UPLOAD = environment.baseURL + '/DataUD/ProcessDUUpload';
export const DU_PROCESS_DOWNLOAD = environment.baseURL + '/DataUD/ProcessDUDownload';

//Bank Details
export const BANK_LEGAL_EMPLOYER_LIST = environment.baseURL + '/Bank/GetPYBankLegalEmployerList';
export const BANK_EMP_HELP = environment.baseURL + '/Bank/GetPYBankEmpHelp';
export const BANK_NAMES = environment.baseURL + '/Bank/GetBankNames';
export const BANK_CURRENCY = environment.baseURL + '/Bank/GetBankCurrency';
export const BANK_PAY_MODES = environment.baseURL + '/Bank/GetBankPayModes';
export const BANK_SUBMIT = environment.baseURL + '/Bank/PYBankSubmit';
export const BANK_FETCH = environment.baseURL + '/Bank/PYBankFetch';
export const BANK_DELETE = environment.baseURL + '/Bank/PYBankDelete';
export const BANK_IMPORT = environment.baseURL + '/Bank/ImportBankDetailsList';
export const BANK_EXPORT = environment.baseURL + '/Bank/ExportBankDetailsList';
//Monthly Details
export const MONTHLY_LEGAL_EMPLOYER_LIST = environment.baseURL + '/PayRoll/GetSPOCLegalEntity';
export const MONTHLY_EMP_HELP = environment.baseURL + '/PayRoll/GetSPOCEmpHelp';
export const MONTHLY_PAY_ROLL = environment.baseURL + '/PayRoll/GetSPOCPayRollType';
export const MONTHLY_PAY_ELEMENT = environment.baseURL + '/PayRoll/GetSPOCPayElementHelp';
export const MONTHLY_INPUT_TYPES = environment.baseURL + '/PayRoll/GetSPOCInputTypeList';
export const MONTHLY_CURRENCY = environment.baseURL + '/PayRoll/GetSPOCCurrencyList';
export const MONTHLY_FETCH = environment.baseURL + '/PayRoll/GetSPOCFetch';
export const MONTHLY_SUBMIT = environment.baseURL + '/PayRoll/SPOCSubmit';
export const MONTHLY_DELETE = environment.baseURL + '/PayRoll/SPOCDelete';
export const MONTHLY_EXPORT = environment.baseURL + '/PayRoll/ExportSPOCList';
export const MONTHLY_IMPORT = environment.baseURL + '/PayRoll/ImportSPOCList';

//EmpDoc Details
export const EMPDOC_LEGAL_EMPLOYER_LIST = environment.baseURL + '/EmployeeDoc/GetEmpDocEmployerList';
export const EMPDOC_EMP_HELP = environment.baseURL + '/EmployeeDoc/GeEmpHelp';
export const EMPDOC_DOC_TYPE = environment.baseURL + '/EmployeeDoc/GetDocumentTypeList';
export const EMPDOC_ARCHIEVE = environment.baseURL + '/EmployeeDoc/GetArchiveList';
export const EMPDOC_FETCH = environment.baseURL + '/EmployeeDoc/GetEmpDocFetchList';
export const EMPDOC_ADDITIONAL_COMBOS = environment.baseURL + '/EmployeeDoc/GeAdditionalComboList';
export const EMPDOC_SUBMIT = environment.baseURL + '/EmployeeDoc/EmpDocSubmit';
export const EMPDOC_DYNAMIC_FIELD = environment.baseURL + '/EmployeeDoc/GetEmpDocDynamicFields';
export const EMPDOC_ARCHIEVE_CHANGE = environment.baseURL + '/EmployeeDoc/OnChangeArchive';
export const EMPDOC_DELETE = environment.baseURL + '/EmployeeDoc/DeleteEmpDoc';
export const EMP_DOC_DOWNLOAD = environment.baseURL + '/EmployeeDoc/DownloadFile';

//Emp Family Details
export const EMPFAMILY_LEGAL_EMPLOYER_LIST = environment.baseURL + '/EmployeeDoc/GetEmpDocEmployerList';
export const EMPFAMILY_EMP_HELP = environment.baseURL + '/EmployeeDoc/GeEmpHelp';
export const EMPFAMILY_DOC_TYPE = environment.baseURL + '/EmployeeDoc/GetDocumentTypeList';
export const EMPFAMILY_ARCHIEVE = environment.baseURL + '/EmployeeDoc/GetArchiveList';
export const EMPFAMILY_FETCH = environment.baseURL + '/EmployeeDoc/GetEmpDocFetchList';
export const EMPFAMILY_ADDITIONAL_COMBOS = environment.baseURL + '/EmployeeDoc/GeAdditionalComboList';
export const EMPFAMILY_SUBMIT = environment.baseURL + '/EmployeeDoc/EmpDocSubmit';
export const EMPFAMILY_DYNAMIC_FIELD = environment.baseURL + '/EmployeeDoc/GetEmpDocDynamicFields';
export const EMPFAMILY_ARCHIEVE_CHANGE = environment.baseURL + '/EmployeeDoc/OnChangeArchive';
export const EMPFAMILY_DELETE = environment.baseURL + '/EmployeeDoc/DeleteEmpDoc';
export const EMPFAMILY_DOC_DOWNLOAD = environment.baseURL + '/EmployeeDoc/DownloadFile';
