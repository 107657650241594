<div class="pageNotFoundBg">
	<div>
		<img class="pnfImg" src="assets/images/404.png" alt="NGP" />
	</div>

	<button mat-raised-button color="primary" class="mt-5" routerLink="/login">Back to Home</button>

	<footer>
		<p>Copyright 2001-{{ currentYear }}. All rights reserved.</p>
	</footer>
</div>
