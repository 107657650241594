import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GET_HELP } from '../../config/app-constants';
import { AuthConfig } from '../../models/authconfig';
import { CustomOverlay } from '../../custom-overlay/custom-overlay.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  year: number = 2023;
  getHelp: any = GET_HELP;

  constructor(public router: Router, private overlay: CustomOverlay) {}

  ngOnInit() {
    const d = new Date();
    this.year = d.getFullYear();
  }
}
