import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable, inject } from '@angular/core';
import { AuthConfig } from '../models/authconfig';
import { Router } from '@angular/router';
import { PopupService } from '../services/popup.service';
import { AuthService } from '../services/auth.service';
@Injectable()
export class HttpCommonInterceptor implements HttpInterceptor {
  private callCount = 0;
  authService = inject(AuthService);
  constructor(public popup: PopupService, public router: Router) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string | null = AuthConfig.accessToken;

    this.showBusyLoader();
    this.callCount++;
    if (token) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.callCount--;
          if (this.callCount === 0) {
            this.hideBusyLoader();
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authService.logout();
        }
        this.callCount--;
        console.log(error);
        if (this.callCount === 0) {
          this.hideBusyLoader();
        }
        return throwError(() => error);
      })
    );
  }
  showBusyLoader() {
    document.getElementById('loader')!.style.display = 'flex';
  }
  hideBusyLoader() {
    document.getElementById('loader')!.style.display = 'none';
  }
}
