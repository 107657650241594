import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { AuthConfig } from '../models/authconfig';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  authService.validateLogin();
  if (!AuthConfig.isLoggedIn) {
    router.navigate(['/login']);
    return false;
  }
  return true;
};
