import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
  inject
} from '@angular/core';
import { ValueAccessorBase } from '../services/value-accessor-base';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[file-upload]'
})
export class CustomFileUploadDirective extends ValueAccessorBase<any> {
  file: File | null = null;
  fileInput = inject(ElementRef);
  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file: File | null = event && event.item(0);

    if (this.onChange) {
      this.onChange(file);
    }
    this.file = file;
  }

  constructor(@Self() @Optional() private control: NgControl) {
    super();
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }
}
