import { Component, inject } from '@angular/core';
import { CUSTOM_OVERLAY_DATA } from '../../custom-overlay/custom-overlay';
import { CustomOverlayRef } from '../../custom-overlay/custom-overlay-ref';
import { PopupData } from '../../models/popup.model';

@Component({
  selector: 'app-custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.scss']
})
export class CustomPopupComponent {
  data: PopupData = inject(CUSTOM_OVERLAY_DATA) as PopupData;
  overlayRef = inject(CustomOverlayRef);
  onClose(data: string): void {
    this.overlayRef.close({ state: data === 'ok' });
  }
}
