import { Component, ViewChild, inject } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  router = inject(Router);

  showChild = false;
  showChild1 = false;
  // @ViewChild('sidenav') sidenav: MatSidenav;

  opened = false;

  toggle(): void {
    this.opened = !this.opened;
  }
  toggleChild(): void {
    this.showChild = !this.showChild;
  }
  toggleChild1(): void {
    this.showChild1 = !this.showChild1;
  }
  //  route(){
  //   if(this.router){
  //     this.opened = false;
  //   }
  // }
  // ngOnInit(): void {
  //   this.route();
  // }
}
