<div class="custom-file" *ngIf="!selected && !file">
	<input
		type="file"
		autocomplete="off"
		class="file-input"
		id="customFile_{{ inputId }}"
		placeholder="Attach Document"
		#fileInput
		(click)="fileInput.value = $any(null)"
		[accept]="fileInputTypes.join(', ')"
	/>
	<div class="file-content" (click)="fileInput.click()">
		<!-- <mat-icon>attach_file</mat-icon> -->
		<img src="assets/images/attach.png" />
	</div>
</div>

<div class="custom-file d-flex gap-3 align-items-center" *ngIf="selected || file">
	<span (click)="triggerDownload()" [class.link]="!file && selected">
		{{ file ? file.name : selected ? selected : placeholder }}
	</span> 
	<img src="assets/images/cancel.png" class="cursor-pointer icon-close" (click)="clearInput()" />
</div>
