export class User {
  public userUid: string | null;
  public token: string | null;
  public name: string | null;

  constructor(data?: any) {
    this.userUid = data?.user_Id ?? '00000000-0000-0000-0000-000000000000';
    this.name = data?.user_Name ?? null;
    this.token = data?.token ?? null;
  }
}
