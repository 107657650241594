import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CustomPopupComponent } from './components/custom-popup/custom-popup.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { KeyPressValidatorDirective } from './directives/key-press-validator.directive';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { LayoutComponent } from './components/layout/layout.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FilterPipe } from './pipes/filter.pipe';
import { CustomFileUploadComponent } from './components/custom-file-upload/custom-file-upload.component';
import { LabelComponent } from './components/label/label.component';
import { SelectDropValueComponent } from './components/select-drop-value/select-drop-value.component';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { InputMaskDirective } from './directives/input-mask.directive';
import { CustomFileUploadDirective } from './directives/file-upload.directive';
import { FileDragAndDropDirective } from './directives/file-drag-drop.directive';
import { DateFormatPipe } from './pipes/date-format.pipe';
@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    PagenotfoundComponent,
    KeyPressValidatorDirective,
    CustomSelectComponent,
    OutsideClickDirective,
    LayoutComponent,
    CustomPopupComponent,
    FilterPipe,
    CustomFileUploadComponent,
    LabelComponent,
    SelectDropValueComponent,
    InputMaskDirective,
    CustomFileUploadDirective,
    FileDragAndDropDirective,
    DateFormatPipe
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    MatSidenavModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatRadioModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    KeyPressValidatorDirective,
    CustomSelectComponent,
    OutsideClickDirective,
    FilterPipe,
    CustomFileUploadComponent,
    LabelComponent,
    SelectDropValueComponent,
    InputMaskDirective,
    CustomFileUploadDirective,
    FileDragAndDropDirective,
    DateFormatPipe
  ]
})
export class SharedModule {}
