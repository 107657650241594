<div class="popup-view">
	<h4 class="d-flex justify-content-between align-items-center">
		{{ data?.title }}
		<mat-icon class="close-btn cursor-pointer" (click)="close()">close</mat-icon>
	</h4>

	<mat-form-field>
		<mat-label>Filter</mat-label>
		<input matInput (keyup)="applyFilter($event)" placeholder="Search..." #input />
	</mat-form-field>
	<div class="masterTable table-responsive">
		<mat-radio-group aria-label="Select an option" [(ngModel)]="activeItem">
			<table mat-table [dataSource]="dataSource">
				<!-- ID Column -->
				<ng-container matColumnDef="action">
					<th mat-header-cell *matHeaderCellDef>Select</th>
					<td mat-cell *matCellDef="let row">
						<mat-radio-button [value]="row.Code"></mat-radio-button>
					</td>
				</ng-container>
				<!-- ID Column -->
				<ng-container matColumnDef="Code">
					<th mat-header-cell *matHeaderCellDef>{{ data?.data ? data?.data : 'ID' }}</th>
					<td mat-cell *matCellDef="let row">{{ row.Code }}</td>
				</ng-container>

				<!-- Name Column -->
				<ng-container matColumnDef="Desc">
					<th mat-header-cell *matHeaderCellDef>{{ data?.label ? data?.label : 'Name' }}</th>
					<td mat-cell *matCellDef="let row">
						<span class="text-truncate emp-name" [title]="row.Desc">{{ row.Desc }}</span>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="activeItem = row.Code" class="cursor-pointer"></tr>

				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row" *matNoDataRow class="text-center">
					<td class="mat-cell" colspan="4">No data found.</td>
				</tr>
			</table>
		</mat-radio-group>
	</div>
	<div class="d-flex justify-content-end mt-2 gap-2">
		<button mat-raised-button (click)="close(true)">Cancel</button>
		<button mat-raised-button (click)="activeItem = null">Reset</button>
		<button mat-raised-button color="primary" [disabled]="!activeItem" (click)="close()">Save</button>
	</div>
</div>
