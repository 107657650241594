import { Component, inject } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { Router, RouterLink } from '@angular/router';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpCommonInterceptor } from '../shared/interceptor/httpcommon.interceptor';

interface LoginForm {
  username: FormControl<string>;
  password: FormControl<string>;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  standalone: true,
  imports: [ReactiveFormsModule, RouterLink]
})
export class RegisterComponent {
  authService = inject(AuthService);
  router = inject(Router);
  _fb = inject(FormBuilder);
  loginForm = this._fb.group<LoginForm>({
    username: this._fb.nonNullable.control('', [Validators.required]),
    password: this._fb.nonNullable.control('', [Validators.required])
  });

  doLogin({ username, password }: { username?: string; password?: string }) {
    if (!username || !password) return;
    this.authService.login(username, password).subscribe((res) => {
      console.log(res);
      this.router.navigate(['/hr']);
    });
  }
}
