<section class="vh-100">
	<div class="container-fluid">
		<div class="row">
			<div class="col-sm-6 text-black">
				<div class="px-5 ms-xl-4">
					<span class="h1 fw-bold mb-0">Register</span>
				</div>

				<div class="d-flex align-items-center px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5">
					<form style="width: 23rem" [formGroup]="loginForm" (submit)="doLogin(loginForm.value)">
						<h3 class="mb-3 pb-3" style="letter-spacing: 1px">Log in</h3>
						<div class="form-outline mb-4">
							<label class="form-label" for="form2Example18">Email address</label>
							<input type="email" id="form2Example18" formControlName="username" class="form-control form-control-lg" />
						</div>

						<div class="form-outline mb-4">
							<label class="form-label" for="form2Example28">Password</label>
							<input type="password" id="form2Example28" formControlName="password" class="form-control form-control-lg" />
						</div>

						<div class="pt-1 mb-4">
							<button class="btn btn-primary btn-lg btn-block" type="submit">Login</button>
						</div>

						<p class="small mb-5 pb-lg-2"><a class="text-muted" href="#!">Forgot password?</a></p>
						<p>
							Don't have an account?
							<a routerLink="/register" class="link-primary cursor-pointer">Register here</a>
						</p>
					</form>
				</div>
			</div>
			<div class="col-sm-6 px-0 d-none d-sm-block">
				<img src="assets/images/loginBg.jpg" alt="Login image" class="w-100 vh-100 login-banner" style="object-fit: cover; object-position: left" />
			</div>
		</div>
	</div>
</section>
