import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagenotfoundComponent } from '../shared/components/pagenotfound/pagenotfound.component';
import { AuthGuard } from '../shared/services/auth.guard';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../register/register.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('../ngpPayslip/ngpPayslip.module').then((m) => m.NgPaySlipModule)
  },
  { path: '**', component: PagenotfoundComponent },
  { path: 'pagenotfound', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
