import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPipe'
})
export class FilterPipe<T> implements PipeTransform {
  transform(value: T[], searchText: string, searchKey: string | string[], nested = false): any {
    if (!value) {
      return [];
    }

    if (!searchText) {
      return value;
    }

    if (nested && typeof searchKey === 'string') {
      return this.filterNChild([...value], searchKey, searchText);
    }

    if (!searchKey) {
      return value.filter((item: any) => item.toLowerCase().includes(searchText.toString().toLowerCase()));
    }

    return value.filter((item: any) => {
      if (searchKey) {
        if (typeof searchKey === 'string') {
          return item[searchKey]?.toLowerCase().includes(searchText.toLowerCase());
        } else {
          let data = '';
          for (let key of searchKey) {
            data += (item[key] ?? '').toString().toLowerCase();
          }
          return data.includes(searchText.toString().toLowerCase());
        }
      }
      return item?.toLowerCase().includes(searchText.toLowerCase());
    });
  }

  filterNChild(value: T[], searchKey: string, searchText: string) {
    const getChildren = (result: T[], el: any) => {
      if (el[searchKey]?.toLowerCase().includes(searchText.toLowerCase())) {
        result.push(el);
        return result;
      }
      if (Array.isArray(el['children'])) {
        const children = el.children.reduce(getChildren, []);
        if (children.length) result.push({ ...el, children });
      }
      return result;
    };

    return value.reduce(getChildren, []);
  }
}
