import { Directive, ElementRef, HostListener, Input, OnChanges, Self, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[inputMask]'
})
export class InputMaskDirective implements OnChanges {
  @Input() mask: string = '';
  @Input() decimal: number = 0;
  @Input() delimitter: string = ',';
  @Input() maxLength: number = 100;
  @Input() clearZero: boolean = false;
  @Input() maxValue!: number;
  @Input() removeMask: boolean = false;
  pattern: string = '';
  constructor(private elm: ElementRef<HTMLInputElement>, @Self() private ngControl: NgControl) {}
  ngOnChanges(changes: SimpleChanges): void {
    const numberPattern = '^(?=(?:\\d\\.?){0,number}$)\\d+$';
    const decimalPattern = '^(?=(?:\\d\\.?){0,number}$)\\d+\\.?(?:\\.\\d{1,decimal})?$';
    const { decimal, maxLength } = changes;
    if (decimal?.currentValue || this.decimal) {
      this.pattern = decimalPattern.replace(/number/g, (maxLength?.currentValue || this.maxLength).toString());
      this.pattern = this.pattern.replace(/decimal/g, (decimal?.currentValue || this.decimal).toString());
    } else {
      this.pattern = numberPattern.replace(/number/g, (maxLength?.currentValue || this.maxLength).toString());
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.validateNumPattern(event)) {
      let val = (event.target as HTMLInputElement).value || '';
      let caretPosition = (event.target as HTMLInputElement).selectionStart ?? val.length;
      if (caretPosition < val.length) {
        const valArr = val.split('');
        valArr.splice(caretPosition, 0, event.key);
        val = valArr.join('');
      } else {
        val += event.key;
      }
      val = val.replace(/[^0-9\\.]/g, '');
      if (val.includes('.')) {
        if (val.length > this.maxLength && val.endsWith('.')) {
          return false;
        }
        if (this.maxValue && Number(val.split('.')[0]) > this.maxValue) {
          return false;
        }
      } else if (val.length > this.maxLength) {
        return false;
      }
      if (
        this.maxValue &&
        Number(val) > this.maxValue &&
        !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(event.key)
      ) {
        return false;
      }
      if (this.maxValue && Number(val) === this.maxValue && event.key === '.') {
        return false;
      }

      if (+event.key >= 0 || +event.key <= 9 || event.key === '.') {
        return new RegExp(this.pattern).test(val);
      }
      return event;
    }
    return false;
  }

  validateNumPattern(event: KeyboardEvent) {
    let allowedKeys = [
      '-',
      '.',
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Tab'
    ];
    return allowedKeys.includes(event.key);
  }

  @HostListener('input')
  onInput() {
    let { value } = this.elm.nativeElement;
    value = value?.replace(/[^0-9\\.]/g, '') || '';
    if (this.delimitter && value) {
      const updatedVal = this.applyDelimitter(value, this.mask);
      this.updateModelValue(updatedVal);
    } else {
      this.updateModelValue(value);
    }
  }
  applyDelimitter(value: string, mask: string): string {
    const [maskNo] = mask.split('.');
    let [valNo, valDec] = value
      .split('.')
      .map((val, i) => (val !== '' ? (i === 0 ? Number(val).toString() : val.toString()) : val));
    let updatedValue = '';
    if (valNo) {
      let valLength: number = valNo.length;
      updatedValue = maskNo.split('').reduceRight((acc, data) => {
        let newVal = acc;
        if (valLength) {
          if (data === this.delimitter) {
            newVal = this.delimitter + newVal;
          } else {
            newVal = valNo.charAt(valLength - 1) + newVal;
            valLength--;
          }
        }
        return newVal;
      }, '');
    }
    if (valDec !== undefined) {
      updatedValue = updatedValue + '.' + valDec;
    }
    return updatedValue;
  }
  @HostListener('focusout', ['$event.target.value'])
  onfocusOut(value: string) {
    if (value === undefined) {
      this.updateModelValue('');
    } else {
      let pureValue = Number(value.replace(/[^0-9\\.]/g, ''));
      let decimal = Number(pureValue.toString().split('.')[1] ?? 0);
      if (!pureValue) {
        if (this.clearZero) this.updateModelValue('');
      } else if (value.startsWith('.')) {
        this.updateModelValue(pureValue.toString());
      } else if (!decimal) {
        if (pureValue > this.maxValue) {
          this.updateModelValue(this.applyDelimitter(this.maxValue.toString(), this.mask));
        } else {
          this.updateModelValue(value.split('.')[0]);
        }
      } else if (decimal) {
        this.updateModelValue(`${value.split('.')[0]}.${pureValue.toString().split('.')[1]}`);
      } else if (value.endsWith('.0')) {
        this.updateModelValue(value.slice(0, -2));
      }
    }
  }
  updateModelValue(value: any): void {
    this.ngControl?.control!.setValue(value, { emitEvent: false });
  }
}
