import { Component, inject } from '@angular/core';
import { PopupService } from '../shared/services/popup.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  popup = inject(PopupService);
  constructor() {}
  ngOnInit(): void {
    // const pop = this.popup.infoPopup('hi', 'test');
    // pop.afterClosed.subscribe((res) => {
    //   console.log(res);
    // });
  }
}
