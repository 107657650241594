import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from '../app/app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from '../shared/shared.module';
import { HttpCommonInterceptor } from '../shared/interceptor/httpcommon.interceptor';
import { CustomOverlayModule } from '../shared/custom-overlay/custom-overlay.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../register/register.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CustomOverlayModule.forRoot(),
    MatSnackBarModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpCommonInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule {}
