import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  AfterViewInit,
  Self,
  Optional,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ValueAccessorBase } from '../../services/value-accessor-base';

@Component({
  selector: 'app-custom-file-upload',
  templateUrl: './custom-file-upload.component.html',
  styleUrls: ['./custom-file-upload.component.css']
})
export class CustomFileUploadComponent extends ValueAccessorBase<any> implements OnInit, OnChanges, AfterViewInit {
  @Input() fileInputTypes = [];
  @Input() inputId = '_file';
  @Input() clear = false;
  @Input() overwrite = false;
  @Input() placeholder = 'Choose file';
  @Output() downloadFile: EventEmitter<void> = new EventEmitter();
  file: File | null = null;
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef<HTMLInputElement>;
  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file: File | null = event && event.item(0);

    if (this.onChange) {
      this.onChange(file);
    }
    this.file = file;
  }

  constructor(@Self() @Optional() private control: NgControl) {
    super();
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clear.currentValue) {
      this.clearInput();
    }
  }
  ngOnInit() {}
  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.overwrite) {
        this.clearInput();
      }
    });
  }

  clearInput() {
    if (this.fileInput) {
      this.fileInput!.nativeElement.value = '';
    }
    this.file = null;
    this.value = null;
    if (this.onChange) {
      this.onChange(null);
    }
  }
  triggerDownload() {
    if (!this.file && this.selected) this.downloadFile.emit();
  }
  // override writeValue(value: null) {
  //   if (!value) {
  //     if (this.fileInput) {
  //       this.fileInput!.nativeElement.value = '';
  //     }
  //     this.file = null;
  //   }
  // }
}
