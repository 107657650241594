import { AfterViewInit, Component, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DropDown } from 'src/modules/ngpPayslip/models/employee';
import { CUSTOM_OVERLAY_DATA } from '../../custom-overlay/custom-overlay';
import { CustomOverlayRef } from '../../custom-overlay/custom-overlay-ref';

@Component({
  selector: 'app-select-drop-value',
  templateUrl: './select-drop-value.component.html',
  styleUrls: ['./select-drop-value.component.css']
})
export class SelectDropValueComponent implements AfterViewInit {
  displayedColumns: string[] = ['action', 'Code', 'Desc'];
  dataSource: MatTableDataSource<DropDown>;
  activeItem!: DropDown | null;
  constructor(@Inject(CUSTOM_OVERLAY_DATA) public data: any, private overlaRef: CustomOverlayRef) {
    // Assign the data to the data source for the table to render
    console.log(data);
    this.activeItem = this.data?.value ?? '';
    this.dataSource = new MatTableDataSource(
      data?.dropdown.map((v: any) => new DropDown(v, this.data?.labelKey, this.data?.dataKey))
    );
  }
  close(cancel = false) {
    this.overlaRef.close(cancel ? undefined : this.activeItem);
  }
  ngAfterViewInit() {}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
