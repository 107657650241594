import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css']
})
export class PagenotfoundComponent implements OnInit {
  currentYear = new Date().getFullYear();
  iconURL: string = 'assets/images/svgs/page-not-found.svg';
  isloading = true;
  constructor(private router: Router) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.imageLoaded(this.iconURL);
  }

  imageLoaded(imageURL: any) {
    try {
      const img = new Image();
      img.src = imageURL;
      img.addEventListener('load', () => {
        this.isloading = false;
      });
    } catch {
      this.isloading = false;
      console.log('Error');
    }
  }
}
