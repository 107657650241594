import { Injectable, inject } from '@angular/core';
import {
  GET_REFERANCE_AREA_LIST,
  GET_LEGAL_EMPLOYEE_LIST,
  GET_FIELD_LIST,
  GET_MASTER_TABLE_LIST,
  SAVE_MASTER_DATA,
  EXCEL_EXPORT,
  EXCEL_UPLOAD,
  EMP_DROPDOWN,
  EMP_FILE_UPLOAD,
  GET_EMP_PERSONAL,
  SAVE_EMP_PERSONAL,
  DELETE_EMP_PERSONAL,
  DELETE_REFRESH_EMP_PERSONAL,
  EMPLOYEE_SEARCH,
  EMPLOYEE_HISTORY_DROPDOWN,
  EMPLOYEE_HISTORY_ONSELECT,
  EMPLOYEE_CODE_SEARCH,
  SAVE_EMP_ASSIGNMENT,
  SAVE_EMP_ASSIGNMENT_REFRESH,
  EMP_ASSIGNMENT_HISTORY_DROPDOWN,
  DELETE_EMP_ASS_PERSONAL,
  DELETE_EMP_REFRESH_ASS_PERSONAL,
  EMP_ADDITIONAL_DROPDOWN,
  SAVE_EMP_SALARY,
  EMP_ASSIGNMENT_HISTORY_ONSELECT,
  EMP_ASSIGNMENT_SEARCH,
  EMP_SALARY_CODE_SEARCH,
  EMP_SALARY_DROPDOWN,
  EMP_SALARY_HISTORY_DROPDOWN,
  EMP_SALARY_PAY_ELEMENT,
  SAVE_EMP_SALARY_REFRESH,
  EMP_FILE_DOWNLOAD,
  EMP_SALARY_CODE_DROPDOWN,
  EMP_SALARY_HISTORY_ONSELECT,
  EMPLOYEE_DYNAMIC_LABELS,
  EMP_ASSIGNMENT_DYNAMIC_LABELS,
  PAYROLL_DROPDOWN_ENTITY,
  PAYROLL_DROPDOWN_PAYROLLTYPE,
  PAYROLL_DROPDOWN_PRPROCESS_PERIOD,
  PAYROLL_DROPDOWN_PRPROCESS_TYPE,
  PAYROLL_DETAILS,
  PAYROLL_REGISTER_DOWNLOAD,
  PAYROLL_PAYSLIP,
  JV_DOWNLOAD,
  TABLE_LABELS,
  PAYROLL_EMPLOYEE_STATUS,
  PROCESS_PAYROLL,
  PAYROLL_EXPORT_TABLE,
  FULL_PROCESS_PAYROLL,
  PAYROLL_EMP_SEARCH,
  BANK_FILE_DOWNLOAD,
  AUTHORIZE,
  AUTHORIZE_ALL,
  UNAUTHORIZE,
  UNAUTHORIZE_ALL,
  PAYROLL_EXCEL_IMPORT,
  PAYROLL_QUEUE_STATUS,
  DU_DATA_TYPE_LIST,
  DU_FILE_TYPE_LIST,
  DU_INTERFACE_FETCH,
  DU_INTERFACE_TYPE_LIST,
  DU_LEGAL_EMPLOYER_LIST,
  DU_MAP_LIST,
  DU_SEPERATOR_LIST,
  DU_SUMMIT,
  DU_TABLE_NAMES,
  DU_GET_DATA,
  DU_DELETE,
  BANK_LEGAL_EMPLOYER_LIST,
  BANK_DELETE,
  BANK_EMP_HELP,
  BANK_FETCH,
  BANK_SUBMIT,
  DU_GET_DETAILS,
  BANK_CURRENCY,
  BANK_NAMES,
  BANK_PAY_MODES,
  MONTHLY_CURRENCY,
  MONTHLY_DELETE,
  MONTHLY_EMP_HELP,
  MONTHLY_FETCH,
  MONTHLY_LEGAL_EMPLOYER_LIST,
  MONTHLY_SUBMIT,
  MONTHLY_INPUT_TYPES,
  MONTHLY_PAY_ELEMENT,
  MONTHLY_PAY_ROLL,
  DU_PROCESS_UPLOAD,
  MONTHLY_EXPORT,
  MONTHLY_IMPORT,
  DU_PROCESS_DOWNLOAD,
  BANK_IMPORT,
  BANK_EXPORT,
  EMPDOC_LEGAL_EMPLOYER_LIST,
  EMPDOC_DOC_TYPE,
  EMPDOC_ARCHIEVE,
  EMPDOC_EMP_HELP,
  EMPDOC_FETCH,
  EMPDOC_ADDITIONAL_COMBOS,
  EMPDOC_SUBMIT,
  EMPDOC_DYNAMIC_FIELD,
  EMPDOC_ARCHIEVE_CHANGE,
  EMPDOC_DELETE,
  EMPFAMILY_LEGAL_EMPLOYER_LIST,
  EMPFAMILY_DOC_TYPE,
  EMPFAMILY_ARCHIEVE,
  EMPFAMILY_EMP_HELP,
  EMPFAMILY_FETCH,
  EMPFAMILY_ADDITIONAL_COMBOS,
  EMPFAMILY_SUBMIT,
  EMPFAMILY_DYNAMIC_FIELD,
  EMPFAMILY_ARCHIEVE_CHANGE,
  EMPFAMILY_DELETE,
  EMP_DOC_DOWNLOAD,
  EMPFAMILY_DOC_DOWNLOAD
} from 'src/modules/shared/config/api-constant';
import { HttpService } from 'src/modules/shared/services/http.service';
import { AreaList, EmpList, FieldList, HRMastersList, HrMasterDetailsList, TableLabels } from '../models/hr-master';
import { EMPTY, Observable, catchError, filter, lastValueFrom, map, throwError } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DropDown, GetDropDownParams } from '../models/employee';
import { EmployeePersonalDetails, GetEmployeeDropdown } from '../models/employee-personal';
import { GetSalaryDropdown, SaveSalary } from '../models/employee-salary';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmployeeAssignmentDetails, GetAssignmentDropdown } from '../models/employee-assignment';
import {
  GetPayrollDetails,
  GetPayrollDropdown,
  PayrollDetails,
  PayrollDetailsItem,
  QueueStatus
} from '../models/employee-payroll';
import {
  DUDropdownResponse,
  DUInterfaceFetchParam,
  DUInterfaceFetchResponse,
  DULinkPopup,
  DownloadLinkProcessData,
  DuSubmit,
  GetDUDropdown,
  GetTableNamesReqRes,
  UploadExcelData
} from '../models/data-upload-download';
import { BankDropdownResponse, BankSubmit, Bankdtl, GetBankDropdown } from '../models/bank-details';
import {
  GetMonthlyDropdown,
  MonthlyDropdownResponse,
  MonthlyFetchResponse,
  MonthlySubmit
} from '../models/monthly-pay';
import {
  EmpDocDropdownResponse,
  GetEmpDocDropdown,
  EmpDocSubmit,
  EmpDocDynamicFields,
  EmpDocFetchResponse,
  EmpDocFetchRequest
} from '../models/employee-docs';
import {
  EmpFamilyDropdownResponse,
  GetEmpFamilyDropdown,
  EmpFamilySubmit,
  EmpFamilyDynamicFields,
  EmpFamilyFetchResponse,
  EmpFamilyFetchRequest
} from '../models/employee-family';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  _snackBar = inject(MatSnackBar);
  http = inject(HttpService);
  SaveSalary(formData: any) {
    throw new Error('Method not implemented.');
  }
  constructor() {}

  getLegalEmpList(error = false): Observable<EmpList[]> {
    return this.http.get<EmpList[]>(GET_LEGAL_EMPLOYEE_LIST).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  getReferenceAreaList(oucode: string, error = false): Observable<AreaList[]> {
    return this.http
      .get<AreaList[]>(GET_REFERANCE_AREA_LIST, { ouCode: oucode })
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  getFieldList(error = false): Observable<FieldList[]> {
    return this.http.get<FieldList[]>(GET_FIELD_LIST).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  getFieldListDynamic(param: any, error = false): Observable<FieldList[]> {
    return this.http.get<FieldList[]>(GET_FIELD_LIST, param).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  getTableLabelDynamic(param: any, error = false): Observable<TableLabels[]> {
    return this.http.get<TableLabels[]>(TABLE_LABELS, param).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  getMasterTableData(data: any, error = false): Observable<HRMastersList> {
    return this.http
      .post<HRMastersList>(GET_MASTER_TABLE_LIST, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  saveMasterData(data: any, error = false): Observable<string | number> {
    return this.http
      .post<string | number>(SAVE_MASTER_DATA, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  exportToExcel(data: any, error = false): Observable<Blob> {
    return this.http
      .postFile<Blob>(EXCEL_EXPORT, data, {}, {}, 'blob')
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  uploadExcel(data: any, error = false): Observable<HrMasterDetailsList[]> {
    return this.http
      .post<HrMasterDetailsList[]>(EXCEL_UPLOAD, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }

  // Employee
  getEmpDropDowns(params: GetDropDownParams, error = false): Observable<DropDown[]> {
    return this.http.get<DropDown[]>(EMP_DROPDOWN, params).pipe(
      map((data: DropDown[]) =>
        data.filter((el) => el.Code !== '-1' && el.code !== '-1').map((x: DropDown) => new DropDown(x))
      ),
      catchError((err) => this.errorHandler(err, error))
    );
  }
  saveEmpPersonalDetails(data: EmployeePersonalDetails, error = false): Observable<any> {
    return this.http
      .post<any>(SAVE_EMP_PERSONAL, data, {}, { contentType: 'multipart' })
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  getEmployeePersonalDetails(data: EmployeePersonalDetails, error = false): Observable<any> {
    return this.http.post<any>(GET_EMP_PERSONAL, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  employeeSearch(data: EmployeePersonalDetails, error = false): Observable<any> {
    return this.http.post<any>(EMPLOYEE_SEARCH, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  employeeHistoryDropdown(data: GetEmployeeDropdown, error = false): Observable<any> {
    return this.http.get<any>(EMPLOYEE_HISTORY_DROPDOWN, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  employeeHistoryOnSelect(data: EmployeePersonalDetails, error = false): Observable<any> {
    return this.http
      .post<any>(EMPLOYEE_HISTORY_ONSELECT, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  employeeCodeSearch(data: GetEmployeeDropdown, error = false): Observable<any> {
    return this.http.get<any>(EMPLOYEE_CODE_SEARCH, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  deleteEmployeePersonalDetails(data: EmployeePersonalDetails, error = false): Observable<any> {
    return this.http.post<any>(DELETE_EMP_PERSONAL, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  deleteEmployeeRefreshPersonalDetails(data: EmployeePersonalDetails, error = false): Observable<any> {
    return this.http
      .post<any>(DELETE_REFRESH_EMP_PERSONAL, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  getEmpPersonalDynamicLabel(data: any, error = false): Observable<any> {
    return this.http.get<any>(EMPLOYEE_DYNAMIC_LABELS, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }

  // Assignment
  getEmpAdditionalDropDowns(params: GetDropDownParams, error = false): Observable<DropDown[]> {
    return this.http.get<DropDown[]>(EMP_ADDITIONAL_DROPDOWN, params).pipe(
      map((data: DropDown[]) =>
        data.filter((el) => el.Code !== '-1' && el.code !== '-1').map((x: DropDown) => new DropDown(x))
      ),
      catchError((err) => this.errorHandler(err, error))
    );
  }
  saveAssignmentDetails(data: EmployeeAssignmentDetails, error = false): Observable<any> {
    return this.http
      .post<any>(SAVE_EMP_ASSIGNMENT, data, {}, { contentType: 'multipart' })
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  getAssignmentDetails(data: EmployeeAssignmentDetails, error = false): Observable<any> {
    return this.http
      .post<any>(SAVE_EMP_ASSIGNMENT_REFRESH, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  assignmentSearch(data: EmployeeAssignmentDetails, error = false): Observable<any> {
    return this.http.post<any>(EMP_ASSIGNMENT_SEARCH, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  assignmentHistoryDropdown(data: GetAssignmentDropdown, error = false): Observable<any> {
    return this.http
      .get<any>(EMP_ASSIGNMENT_HISTORY_DROPDOWN, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  assignmentHistoryOnSelect(data: EmployeeAssignmentDetails, error = false): Observable<any> {
    return this.http
      .post<any>(EMP_ASSIGNMENT_HISTORY_ONSELECT, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  assignmentCodeSearch(data: GetAssignmentDropdown, error = false): Observable<any> {
    return this.http.get<any>(EMPLOYEE_CODE_SEARCH, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  deleteAssignmentDetails(data: EmployeeAssignmentDetails, error = false): Observable<any> {
    return this.http.post<any>(DELETE_EMP_ASS_PERSONAL, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  deleteAssignmentRefreshDetails(data: EmployeeAssignmentDetails, error = false): Observable<any> {
    return this.http
      .post<any>(DELETE_EMP_REFRESH_ASS_PERSONAL, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  getAdditionalEmpPersonalDynamicLabel(data: any, error = false): Observable<any> {
    return this.http
      .get<any>(EMP_ASSIGNMENT_DYNAMIC_LABELS, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }

  // Salary
  saveSalaryDetails(data: SaveSalary, error = false): Observable<any> {
    return this.http.post<any>(SAVE_EMP_SALARY, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  saveSalaryRefreshDetails(data: SaveSalary, error = false): Observable<any> {
    return this.http.post<any>(SAVE_EMP_SALARY_REFRESH, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  salaryDropdown(data: GetDropDownParams, error = false): Observable<any> {
    return this.http.get<any>(EMP_SALARY_DROPDOWN, data).pipe(
      map((data: DropDown[]) =>
        data.filter((el) => el.Code !== '-1' && el.code !== '-1').map((x: DropDown) => new DropDown(x))
      ),
      catchError((err) => this.errorHandler(err, error))
    );
  }
  salaryEmpCodeDropdown(data: GetSalaryDropdown, error = false): Observable<any> {
    return this.http.get<any>(EMP_SALARY_CODE_DROPDOWN, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  salaryHistorySearch(data: GetSalaryDropdown, error = false): Observable<any> {
    return this.http
      .get<any>(EMP_SALARY_HISTORY_DROPDOWN, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  salaryCodeSearch(data: SaveSalary, error = false): Observable<any> {
    return this.http.post<any>(EMP_SALARY_CODE_SEARCH, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  salaryHistoryOnSelect(data: SaveSalary, error = false): Observable<any> {
    return this.http
      .post<any>(EMP_SALARY_HISTORY_ONSELECT, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  salaryPayElement(data: GetSalaryDropdown, error = false): Observable<any> {
    return this.http.get<any>(EMP_SALARY_PAY_ELEMENT, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }

  uploadEmpFiles(file: File, error = false): Observable<any> {
    const formData = new FormData();
    formData.append('empDetails', file);
    return this.http
      .post<any>(EMP_FILE_UPLOAD, formData, {}, { contentType: 'multipart' })
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  async downloadEmpFiles(filename: string, error = false): Promise<any> {
    return lastValueFrom(
      this.http
        .getFile<Blob>(EMP_FILE_DOWNLOAD, { filename }, {}, 'blob')
        .pipe(catchError((err) => this.errorHandler(err, error)))
    );
  }
  // Payroll

  getPayrollEntity(data: GetPayrollDropdown, error = false): Observable<any> {
    return this.http.get<any>(PAYROLL_DROPDOWN_ENTITY, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  getPayrollType(data: GetPayrollDropdown, error = false): Observable<any> {
    return this.http.get<any>(PAYROLL_DROPDOWN_PAYROLLTYPE, data).pipe(
      map((data: DropDown[]) =>
        data.filter((el) => el.Code !== '-1' && el.code !== '-1').map((x: DropDown) => new DropDown(x))
      ),
      catchError((err) => this.errorHandler(err, error))
    );
  }
  getPayrollProcessPeriod(data: GetPayrollDropdown, error = false): Observable<any> {
    return this.http.get<any>(PAYROLL_DROPDOWN_PRPROCESS_PERIOD, data).pipe(
      map((data: DropDown[]) =>
        data.filter((el) => el.Code !== '-1' && el.code !== '-1').map((x: DropDown) => new DropDown(x))
      ),
      catchError((err) => this.errorHandler(err, error))
    );
  }
  getPayrollProcessType(data: GetPayrollDropdown, error = false): Observable<any> {
    return this.http.get<any>(PAYROLL_DROPDOWN_PRPROCESS_TYPE, data).pipe(
      map((data: DropDown[]) =>
        data.filter((el) => el.Code !== '-1' && el.code !== '-1').map((x: DropDown) => new DropDown(x))
      ),
      catchError((err) => this.errorHandler(err, error))
    );
  }
  getEmployeeStatus(data: GetPayrollDropdown, error = false): Observable<any> {
    return this.http.get<any>(PAYROLL_EMPLOYEE_STATUS, data).pipe(
      map((data: DropDown[]) =>
        data.filter((el) => el.Code !== '-1' && el.code !== '-1').map((x: DropDown) => new DropDown(x))
      ),
      catchError((err) => this.errorHandler(err, error))
    );
  }
  getPayrollDetails(data: GetPayrollDetails, error = false): Observable<PayrollDetails> {
    return this.http.post<any>(PAYROLL_DETAILS, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  getRegisterDownload(data: GetPayrollDetails, error = false): Observable<HttpResponse<Blob>> {
    return this.http
      .postFile<any>(PAYROLL_REGISTER_DOWNLOAD, data, {}, {}, 'blob', true)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  generatePayslip(data: GetPayrollDetails, error = false): Observable<HttpResponse<Blob>> {
    return this.http
      .postFile<any>(PAYROLL_PAYSLIP, data, {}, {}, 'blob', true)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  generateJV(data: GetPayrollDetails, error = false): Observable<HttpResponse<Blob>> {
    return this.http
      .postFile<any>(JV_DOWNLOAD, data, {}, {}, 'blob', true)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  generateBankFile(data: GetPayrollDetails, error = false): Observable<HttpResponse<Blob>> {
    return this.http
      .postFile<any>(BANK_FILE_DOWNLOAD, data, {}, {}, 'blob', true)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  generateAuthorizeAll(data: GetPayrollDetails, error = false): Observable<any> {
    return this.http.post<any>(AUTHORIZE_ALL, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  generateUnAuthorizeAll(data: GetPayrollDetails, error = false): Observable<any> {
    return this.http.post<any>(UNAUTHORIZE_ALL, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  generateAuthorize(data: GetPayrollDetails, error = false): Observable<any> {
    return this.http.post<any>(AUTHORIZE, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  generateUnAuthorize(data: GetPayrollDetails, error = false): Observable<any> {
    return this.http.post<any>(UNAUTHORIZE, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  generateProcess(data: GetPayrollDetails, error = false): Observable<any> {
    return this.http.post<any>(PROCESS_PAYROLL, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  generateFullProcess(data: GetPayrollDetails, error = false): Observable<any> {
    return this.http.post<any>(FULL_PROCESS_PAYROLL, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  exportTableData(data: PayrollDetailsItem[], error = false): Observable<any> {
    return this.http
      .postFile<any>(PAYROLL_EXPORT_TABLE, data, {}, {}, 'blob', true)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  payrollEmployeeCodeSearch(data: GetPayrollDropdown, error = false): Observable<any> {
    return this.http.get<any>(PAYROLL_EMP_SEARCH, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  payrollQueueStatus(data: GetPayrollDropdown, error = false): Observable<QueueStatus[]> {
    return this.http
      .get<QueueStatus[]>(PAYROLL_QUEUE_STATUS, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  payrollImportExcel(data: any, error = false): Observable<PayrollDetails> {
    return this.http
      .post<PayrollDetails>(PAYROLL_EXCEL_IMPORT, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }

  // Data upload / download
  duInterfaceTypeList(data: GetDUDropdown, error = false): Observable<DUDropdownResponse[]> {
    return this.http
      .get<DUDropdownResponse[]>(DU_INTERFACE_TYPE_LIST, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  duFileTypeList(data: GetDUDropdown, error = false): Observable<DUDropdownResponse[]> {
    return this.http
      .get<DUDropdownResponse[]>(DU_FILE_TYPE_LIST, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  duMapList(data: GetDUDropdown, error = false): Observable<DUDropdownResponse[]> {
    return this.http
      .get<DUDropdownResponse[]>(DU_MAP_LIST, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  duDataTypeList(data: GetDUDropdown, error = false): Observable<DUDropdownResponse[]> {
    return this.http
      .get<DUDropdownResponse[]>(DU_DATA_TYPE_LIST, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  duLegalEmployer(data: GetDUDropdown, error = false): Observable<DUDropdownResponse[]> {
    return this.http
      .get<DUDropdownResponse[]>(DU_LEGAL_EMPLOYER_LIST, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  duSeperator(data: GetDUDropdown, error = false): Observable<DUDropdownResponse[]> {
    return this.http
      .get<DUDropdownResponse[]>(DU_SEPERATOR_LIST, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  duTableNames(data: GetTableNamesReqRes, error = false): Observable<GetTableNamesReqRes[]> {
    return this.http
      .post<GetTableNamesReqRes[]>(DU_TABLE_NAMES, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  duInterfaceFetch(data: DUInterfaceFetchParam, error = false): Observable<DUInterfaceFetchResponse[]> {
    return this.http
      .post<DUInterfaceFetchResponse[]>(DU_INTERFACE_FETCH, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  dugetDetailData(data: DULinkPopup, error = false): Observable<DuSubmit> {
    return this.http.post<DuSubmit>(DU_GET_DETAILS, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  duSubmit(data: DuSubmit, error = false): Observable<string> {
    return this.http.post<string>(DU_SUMMIT, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  duDelete(data: DuSubmit, error = false): Observable<string> {
    return this.http.post<string>(DU_DELETE, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  duGetData(data: DuSubmit, error = false): Observable<DuSubmit> {
    return this.http.post<DuSubmit>(DU_GET_DATA, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  duProcessUpload(data: UploadExcelData, error = false): Observable<string> {
    return this.http.post<string>(DU_PROCESS_UPLOAD, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  duProcessDownload(data: DownloadLinkProcessData, error = false): Observable<HttpResponse<Blob>> {
    return this.http
      .postFile<HttpResponse<Blob>>(DU_PROCESS_DOWNLOAD, data, {}, {}, 'blob', true)
      .pipe(catchError((err) => this.errorHandler(err, error)));
    // return this.http.post<string>(DU_PROCESS_DOWNLOAD, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }

  // Bank
  bankLegalEmployer(loginUser: string, error = false): Observable<BankDropdownResponse[]> {
    return this.http
      .get<BankDropdownResponse[]>(BANK_LEGAL_EMPLOYER_LIST, { loginUser })
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  bankEmpHelp(data: GetBankDropdown, error = false): Observable<BankDropdownResponse[]> {
    return this.http
      .get<BankDropdownResponse[]>(BANK_EMP_HELP, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  bankNames(data: GetBankDropdown, error = false): Observable<BankDropdownResponse[]> {
    return this.http
      .post<BankDropdownResponse[]>(BANK_NAMES, {}, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  bankCurrency(data: GetBankDropdown, error = false): Observable<BankDropdownResponse[]> {
    return this.http
      .post<BankDropdownResponse[]>(BANK_CURRENCY, {}, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  bankPayCode(data: GetBankDropdown, error = false): Observable<BankDropdownResponse[]> {
    return this.http
      .post<BankDropdownResponse[]>(BANK_PAY_MODES, {}, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  bankSubmit(data: BankSubmit, error = false): Observable<string> {
    return this.http.post<string>(BANK_SUBMIT, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  bankFetch(data: BankSubmit, error = false): Observable<BankSubmit> {
    return this.http.post<BankSubmit>(BANK_FETCH, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  bankDelete(data: BankSubmit, error = false): Observable<string> {
    return this.http.post<string>(BANK_DELETE, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  bankImport(data: any, error = false): Observable<Bankdtl[]> {
    return this.http.post<Bankdtl[]>(BANK_IMPORT, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  bankExport(data: Bankdtl[], error = false): Observable<HttpResponse<Blob>> {
    return this.http
      .postFile<HttpResponse<Blob>>(BANK_EXPORT, data, {}, {}, 'blob', true)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }

  // Monthly Details
  monthlyLegalEmployer(loginUser: string, error = false): Observable<MonthlyDropdownResponse[]> {
    return this.http
      .get<MonthlyDropdownResponse[]>(MONTHLY_LEGAL_EMPLOYER_LIST, { loginUser })
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  monthlyEmpHelp(data: GetMonthlyDropdown, error = false): Observable<MonthlyDropdownResponse[]> {
    return this.http
      .get<MonthlyDropdownResponse[]>(MONTHLY_EMP_HELP, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  monthlyPayRoll(data: GetMonthlyDropdown, error = false): Observable<MonthlyDropdownResponse[]> {
    return this.http
      .get<MonthlyDropdownResponse[]>(MONTHLY_PAY_ROLL, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  monthlyPayElement(data: GetMonthlyDropdown, error = false): Observable<MonthlyDropdownResponse[]> {
    return this.http
      .get<MonthlyDropdownResponse[]>(MONTHLY_PAY_ELEMENT, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  monthlyInputTypes(data: GetMonthlyDropdown, error = false): Observable<MonthlyDropdownResponse[]> {
    return this.http
      .get<MonthlyDropdownResponse[]>(MONTHLY_INPUT_TYPES, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  monthlyCurrency(data: GetMonthlyDropdown, error = false): Observable<MonthlyDropdownResponse[]> {
    return this.http
      .get<MonthlyDropdownResponse[]>(MONTHLY_CURRENCY, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  monthlyFetch(data: MonthlySubmit, error = false): Observable<MonthlyFetchResponse[]> {
    return this.http
      .post<MonthlyFetchResponse[]>(MONTHLY_FETCH, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  monthlySubmit(data: MonthlySubmit, error = false): Observable<string> {
    return this.http.post<string>(MONTHLY_SUBMIT, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  monthlyDelete(data: MonthlySubmit, error = false): Observable<string> {
    return this.http.post<string>(MONTHLY_DELETE, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  monthlyImport(data: any, error = false): Observable<MonthlyFetchResponse[]> {
    return this.http
      .post<MonthlyFetchResponse[]>(MONTHLY_IMPORT, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  monthlyExport(data: MonthlyFetchResponse[], error = false): Observable<HttpResponse<Blob>> {
    return this.http
      .postFile<HttpResponse<Blob>>(MONTHLY_EXPORT, data, {}, {}, 'blob', true)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }

  // EmpDoc
  empdocLegalEmployer(loginUser: string, error = false): Observable<EmpDocDropdownResponse[]> {
    return this.http
      .get<EmpDocDropdownResponse[]>(EMPDOC_LEGAL_EMPLOYER_LIST, { loginUser })
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empdocEmpHelp(data: GetEmpDocDropdown, error = false): Observable<EmpDocDropdownResponse[]> {
    return this.http
      .get<EmpDocDropdownResponse[]>(EMPDOC_EMP_HELP, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empdocDocType(data: GetEmpDocDropdown, error = false): Observable<EmpDocDropdownResponse[]> {
    return this.http
      .get<EmpDocDropdownResponse[]>(EMPDOC_DOC_TYPE, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empdocAdditionalCombo(data: GetEmpDocDropdown, error = false): Observable<EmpDocDropdownResponse[]> {
    return this.http
      .get<EmpDocDropdownResponse[]>(EMPDOC_ADDITIONAL_COMBOS, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empdocDynamicField(data: GetEmpDocDropdown, error = false): Observable<EmpDocDynamicFields> {
    return this.http
      .get<EmpDocDynamicFields>(EMPDOC_DYNAMIC_FIELD, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empdocArchieve(data: EmpDocFetchRequest, error = false): Observable<EmpDocDropdownResponse[]> {
    return this.http
      .post<EmpDocDropdownResponse[]>(EMPDOC_ARCHIEVE, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empdocArchieveChange(data: any, error = false): Observable<EmpDocFetchResponse> {
    return this.http
      .post<EmpDocFetchResponse>(EMPDOC_ARCHIEVE_CHANGE, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empdocFetch(data: EmpDocFetchRequest, error = false): Observable<EmpDocFetchResponse[]> {
    return this.http
      .post<EmpDocFetchResponse[]>(EMPDOC_FETCH, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empdocSubmit(data: EmpDocSubmit, error = false): Observable<string> {
    return this.http.post<string>(EMPDOC_SUBMIT, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empdocDelete(data: any, error = false): Observable<string> {
    return this.http.post<string>(EMPDOC_DELETE, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  async downloadEmpDocs(filename: string, error = false): Promise<any> {
    return lastValueFrom(
      this.http
        .getFile<Blob>(EMP_DOC_DOWNLOAD, { filename }, {}, 'blob')
        .pipe(catchError((err) => this.errorHandler(err, error)))
    );
  }

  // EmpFamily
  empfamilyLegalEmployer(loginUser: string, error = false): Observable<EmpFamilyDropdownResponse[]> {
    return this.http
      .get<EmpFamilyDropdownResponse[]>(EMPFAMILY_LEGAL_EMPLOYER_LIST, { loginUser })
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empfamilyEmpHelp(data: GetEmpFamilyDropdown, error = false): Observable<EmpFamilyDropdownResponse[]> {
    return this.http
      .get<EmpFamilyDropdownResponse[]>(EMPFAMILY_EMP_HELP, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empfamilyDocType(data: GetEmpFamilyDropdown, error = false): Observable<EmpFamilyDropdownResponse[]> {
    return this.http
      .get<EmpFamilyDropdownResponse[]>(EMPFAMILY_DOC_TYPE, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empfamilyAdditionalCombo(data: GetEmpFamilyDropdown, error = false): Observable<EmpFamilyDropdownResponse[]> {
    return this.http
      .get<EmpFamilyDropdownResponse[]>(EMPFAMILY_ADDITIONAL_COMBOS, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empfamilyDynamicField(data: GetEmpFamilyDropdown, error = false): Observable<EmpFamilyDynamicFields> {
    return this.http
      .get<EmpFamilyDynamicFields>(EMPFAMILY_DYNAMIC_FIELD, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empfamilyArchieve(data: EmpFamilyFetchRequest, error = false): Observable<EmpFamilyDropdownResponse[]> {
    return this.http
      .post<EmpFamilyDropdownResponse[]>(EMPFAMILY_ARCHIEVE, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empfamilyArchieveChange(data: any, error = false): Observable<EmpFamilyFetchResponse> {
    return this.http
      .post<EmpFamilyFetchResponse>(EMPFAMILY_ARCHIEVE_CHANGE, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empfamilyFetch(data: EmpFamilyFetchRequest, error = false): Observable<EmpFamilyFetchResponse[]> {
    return this.http
      .post<EmpFamilyFetchResponse[]>(EMPFAMILY_FETCH, data)
      .pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empfamilySubmit(data: EmpFamilySubmit, error = false): Observable<string> {
    return this.http.post<string>(EMPFAMILY_SUBMIT, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  empfamilyDelete(data: any, error = false): Observable<string> {
    return this.http.post<string>(EMPFAMILY_DELETE, data).pipe(catchError((err) => this.errorHandler(err, error)));
  }
  async downloadEmpFamilyDocs(filename: string, error = false): Promise<any> {
    return lastValueFrom(
      this.http
        .getFile<Blob>(EMPFAMILY_DOC_DOWNLOAD, { filename }, {}, 'blob')
        .pipe(catchError((err) => this.errorHandler(err, error)))
    );
  }

  errorHandler(err: HttpErrorResponse, returnErr = false) {
    console.log(err);
    if (returnErr) {
      return throwError(() => err);
    }
    this.openSnackBar(err.error?.title ?? err.message, 'error');
    return EMPTY;
  }

  async downloadEmpFamilyDoc(fileName: string) {
    const res = await this.downloadEmpFamilyDocs(fileName);
    if (res) {
      this.saveFileToLocal(res, fileName);
    }
  }
  async downloadEmpDoc(fileName: string) {
    const res = await this.downloadEmpDocs(fileName);
    if (res) {
      this.saveFileToLocal(res, fileName);
    }
  }
  async downloadFile(fileName: string) {
    const res = await this.downloadEmpFiles(fileName);
    if (res) {
      this.saveFileToLocal(res, fileName);
    }
  }

  saveFileToLocal(data: Blob, fileName = '') {
    const url = (window.URL || window.webkitURL).createObjectURL(data);
    let a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  openSnackBar(message: string, type = 'success') {
    this._snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: type === 'error' ? 10000 : 3000,
      panelClass: [type + '-snackbar']
    });
  }
}
