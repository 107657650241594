import { Component, Input, forwardRef } from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'app-label',
  template: `
    <label [for]="for">
      <ng-content></ng-content>
      <span class="c-required" *ngIf="required">*</span>
      <ng-content select="[icon]"></ng-content>
    </label>
  `,
  styles: [
    `
      .c-required {
        margin-left: 2px;
        color: red;
      }
    `
  ]
})
export class LabelComponent {
  @Input() for?: string;
  @Input() control!: FormControl<any> | AbstractControl<any, any>;

  get required(): boolean {
    return this.control!.hasValidator(Validators.required);
  }
}
