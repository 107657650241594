import { User } from './users';

export class AuthConfig {
  public static userInfo: User;
  public static isLoggedIn = false;
  public static UserProfile: string;
  public static Name: string;
  public static Username: string;
  public static email: string;
  public static status: string;
  public static accessToken: string;
}
