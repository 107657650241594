<mat-toolbar color="primary">
	<button (click)="menuToggled.emit(true)" mat-icon-button>
		<mat-icon style="color: #353535;">menu</mat-icon>
	</button>
	<span class="logoBg" style="background: #fff;padding: 1px 0x;margin: 0 10px 0 0;">
	<img src="assets/images/logo.png" style="width: 50px;">
</span>
	<span class="title" (click)="menuToggled.emit(true)" routerLink="/"></span>
	<span class="spacer"></span>

	<button mat-icon-button (click)="reload()">
		<mat-icon style="color: #353535;"> refresh</mat-icon>
	</button> 
	

	<button class="userText" [matMenuTriggerFor]="menu">
		<span >{{ userFirstLetter }}</span>
	</button>
	<mat-menu #menu="matMenu">
		<span class="welcome-text">{{ user }}</span>
		<button mat-menu-item (click)="doLogout()">
			<span>Logout</span>
		</button>
	</mat-menu>
</mat-toolbar>
