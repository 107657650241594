import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { INCOMING_DATES } from '../config/app-constants';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string | null, format?: string): unknown {
    if (
      (typeof value === 'string' && value?.includes('1900')) ||
      value === '1900-01-01' ||
      value === '0001-01-01T00:00:00' ||
      !value
    )
      return '';
    return moment(value, INCOMING_DATES).format(format);
  }
}
