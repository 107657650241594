import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[filedd]'
})
export class FileDragAndDropDirective {
  fileOver = false;
  @Input() accept: string[] = [];
  @Output() fileDropped = new EventEmitter();
  @HostListener('dragover', ['$event']) dragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
    console.log('Drag over');
  }
  @HostListener('dragleave', ['$event']) dragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    console.log('Drag leave');
  }
  @HostListener('drop', ['$event']) drop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    console.log('Drop');
    this.fileOver = false;
    const files = event.dataTransfer.files;
    const filesList = [];
    for (const file of files) {
      let ext = file.name.substring(file.name.lastIndexOf('.'));
      if (this.accept.includes(ext) || !this.accept.length) {
        filesList.push(file);
      }
    }
    if (filesList.length) {
      this.fileDropped.emit(files);
      console.log('Droped ' + files.length + ' files');
    }
  }
}
