<!-- start .site-wrap -->
<!-- <div class="site-wrap">
	<div class="site-page">
		<app-header></app-header>
		<router-outlet></router-outlet>
	</div>
	<app-footer></app-footer>
</div> -->

<div>
	<app-header (menuToggled)="toggle()"></app-header>

	<mat-drawer-container>
		<mat-drawer [opened]="opened">
			<ul>
				<li routerLinkActive="active" [routerLink]="'/home'" (click)="toggle()">
					<a>
						<img src="assets/images/home.png" />
						Home
					</a>
				</li>
				<li routerLinkActive="active" [routerLink]="'/hr'" (click)="toggle()">
					<a>
						<img src="assets/images/grand.png" />
						Hr Masters
					</a>
				</li>
				<li class="subMenuBg" (click)="toggleChild()">
					<a class="cursor-pointer">
						<img src="assets/images/employee.png" />
						Employee Masters
					</a>
					<ul class="subMenu" [class.show]="showChild">
						<li routerLinkActive="active" [routerLink]="'/createemp/full'" (click)="toggle()">
							<a>Employee Creation Compact</a>
						</li>
						<li routerLinkActive="active" [routerLink]="'/createemp/personal'" (click)="toggle()">
							<a>Employee Personal Details</a>
						</li>
						<li routerLinkActive="active" [routerLink]="'/createemp/assignment'" (click)="toggle()">
							<a>Asssignment Details</a>
						</li>
						<li routerLinkActive="active" [routerLink]="'/createemp/salary'" (click)="toggle()">
							<a>Salary Details</a>
						</li>
					</ul>
				</li>
				<li class="subMenuBg" (click)="toggleChild1()">
					<a class="cursor-pointer">
						<img src="assets/images/payment.png" />
						Payroll
					</a>
					<ul class="subMenu" [class.show]="showChild1">
						<li routerLinkActive="active" [routerLink]="'/payroll'" (click)="toggle()">
							<a>Payroll Dashboard</a>
						</li>
						<li routerLinkActive="active" [routerLink]="'/paymonthly'" (click)="toggle()">
							<a>Pay Monthly Inputs</a>
						</li>
					</ul>
				</li>
				<li routerLinkActive="active" [routerLink]="'/dataupload'" (click)="toggle()">
					<a>
						<img src="assets/images/dataUD.png" />
						Data Upload/Download
					</a>
				</li>
				<li routerLinkActive="active" [routerLink]="'/bankdetails'" (click)="toggle()">
					<a>
						<img src="assets/images/bankDetails.png" />
						Bank Details
					</a>
				</li>
				<li routerLinkActive="active" [routerLink]="'/employeedetails'" (click)="toggle()">
					<a>
						<img src="assets/images/bankDetails.png" />
						Employee Documents
					</a>
				</li>
				<li routerLinkActive="active" [routerLink]="'/employeefamily'" (click)="toggle()">
					<a>
						<img src="assets/images/bankDetails.png" />
						Employee Family
					</a>
				</li>
			</ul>
			<!-- <app-menu-item [menu]="menu"></app-menu-item> -->
		</mat-drawer>
		<mat-drawer-content [class.margin-left]="opened">
			<router-outlet></router-outlet>
		</mat-drawer-content>
	</mat-drawer-container>
	<!-- <app-footer></app-footer> -->
</div>
