import { Component, inject, EventEmitter, Output, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  authService = inject(AuthService);

  @Output() menuToggled = new EventEmitter<boolean>();

  user: string = 'User';
  userFirstLetter: string = 'UserFirstLetter';
  ngOnInit(): void {
    this.authService.user.subscribe((res) => {
      if (res?.name) {
        this.user = res.name;
        this.userFirstLetter = res.name[0];
      }
    });
  }
  doLogout() {
    this.authService.logout();
  }
  reload() {
    window.location.reload();
  }
}
