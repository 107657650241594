import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { User } from '../models/users';
import { AuthConfig } from '../models/authconfig';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import { LOGIN_USER } from '../config/api-constant';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  router = inject(Router);
  http = inject(HttpService);
  constructor() {}

  getUser() {
    return this.user.value;
  }

  setUser(user: User) {
    sessionStorage.setItem('NGPUI_U', btoa(JSON.stringify(user)));
    this.user.next(user);
  }

  validateLogin() {
    const user = sessionStorage.getItem('NGPUI_U');
    if (user) {
      const decodedUser = JSON.parse(atob(user));
      if (decodedUser) {
        AuthConfig.isLoggedIn = true;
        AuthConfig.accessToken = decodedUser?.token;
        AuthConfig.Username = decodedUser?.name;
        this.user.next(decodedUser);
      }
    }
  }

  login<T>(username: string, password: string): Observable<T> {
    return this.http.post<T>(LOGIN_USER, { User_Name: username, PAssword: password, token: '', error: '' }).pipe(
      map((data: any) => {
        if (data?.sucuess) {
          AuthConfig.accessToken = data?.token;
          AuthConfig.Username = data?.user_Name;
          AuthConfig.userInfo = new User(data);
          this.setUser(new User(data));
          return data;
        }
        return data;
      })
    );
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }
}
