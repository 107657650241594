import { Injectable, inject } from '@angular/core';
import { CustomPopupComponent } from '../components/custom-popup/custom-popup.component';
import { CustomOverlay } from '../custom-overlay/custom-overlay.service';
import { CustomOverlayConfigOptions } from '../custom-overlay/custom-overlay';
import { PopupData } from '../models/popup.model';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  overlay = inject(CustomOverlay);

  constructor() {}
  private openpopup(data: PopupData, config?: CustomOverlayConfigOptions) {
    return this.overlay.open({
      component: CustomPopupComponent,
      position: 'center',
      data: data,
      options: { hasBackdrop: true, panelClass: ['c-popup'], preventBackdropClick: true, ...config }
    });
  }

  infoPopup(title: string, desc: string, okText = 'Ok', cancelText = 'Cancel', close = true, closePrev = true) {
    let obj = {
      title,
      desc,
      ok: { show: okText ? true : false, text: okText },
      cancel: { show: cancelText ? true : false, text: cancelText },
      close: close
    };
    return this.openpopup(obj, { closePrevious: closePrev });
  }

  apiErrorPopup(title: string, desc: string = 'OOPS!!! Something went wrong.') {
    let obj = {
      title,
      desc,
      ok: { show: false },
      cancel: { show: false },
      close: false
    };
    return this.openpopup(obj, { closePrevious: true });
  }
}
