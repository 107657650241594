<h1>
	{{ data.title }}
	<button *ngIf="data?.close" (click)="onClose('close')">close</button>
</h1>
<div>
	<p>{{ data.desc }}</p>
</div>
<div>
	<button *ngIf="data?.ok?.show" (click)="onClose('ok')">{{ data.ok?.text ?? 'OK' }}</button>
	<button *ngIf="data?.cancel?.show" (click)="onClose('cancel')">{{ data.cancel?.text ?? 'Cancel' }}</button>
</div>
