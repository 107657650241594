import { FormControl } from '@angular/forms';
import { AuthConfig } from 'src/modules/shared/models/authconfig';

export class EmployeeDetils {
  employee_code: string;
  employee_name: string;
  status: string;
  position: string;
  department: string;
  job: string;
  grade: string;
  worklocation: string;
  constructor(data?: EmployeeDetils) {
    this.employee_code = data?.employee_code ?? '';
    this.employee_name = data?.employee_name ?? '';
    this.status = data?.status ?? '';
    this.position = data?.position ?? '';
    this.department = data?.department ?? '';
    this.job = data?.job ?? '';
    this.grade = data?.grade ?? '';
    this.worklocation = data?.worklocation ?? '';
  }
}

export class GetDropDownParams {
  OuCode?: string;
  OuDesc?: string;
  loginUser?: string;
  CTC_Unique_ID?: string;
  CTC_Emp_code?: string;
  CTC_Action_code?: string;
  CTC_Action_Desc?: string;
  ComboType?: string;
  constructor(
    OuCode: string,
    OuDesc: string,
    ComboType: string,
    CTC_Unique_ID?: string,
    CTC_Emp_code?: string,
    CTC_Action_code?: string,
    CTC_Action_Desc?: string
  ) {
    this.OuCode = OuCode;
    this.OuDesc = OuDesc;
    this.loginUser = AuthConfig.Username;
    this.CTC_Unique_ID = CTC_Unique_ID;
    this.CTC_Emp_code = CTC_Emp_code;
    this.CTC_Action_code = CTC_Action_code;
    this.CTC_Action_Desc = CTC_Action_Desc;
    this.ComboType = ComboType;
  }
}

export class DropDown {
  Code: string;
  Desc: string;
  code?: string;
  desc?: string;
  error?: string;
  constructor(data?: DropDown | { [key: string]: string }, label?: string, val?: string) {
    this.Code = data?.code ?? '';
    this.Desc = data?.desc ?? '';
    this.code = data?.code ?? '';
    this.desc = data?.desc ?? '';
    this.error = data?.error ?? '';
    if (label && data) {
      this.Desc = data[label as keyof DropDown] as string;
      this.desc = data[label as keyof DropDown] as string;
    }
    if (val && data) {
      this.Code = data[val as keyof DropDown] as string;
      this.code = data[val as keyof DropDown] as string;
    }
  }
}
export class PayElement extends DropDown {
  classification: string;
  constructor(data?: { [key: string]: string }) {
    super(data);
    this.classification = data?.classification ?? '';
  }
}
